import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { TutorialMapKind } from '@asksuzy/typescript-sdk';
import { MONTHS } from './months';
import moment from 'moment';
import { zip } from 'rxjs';

@Component({
  selector: 'suzy-action-forms-open-ended',
  templateUrl: './open-ended.component.html',
  styleUrls: ['./open-ended.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenEndedComponent implements OnInit {
  @Input() isErrorMessage: string;
  @Input() action;
  @Input() isSuccess: boolean;
  @Input() isLoading: boolean;
  @Input() isSpecialMission: boolean;
  @Input() isValid;
  @Input() disableSkip;
  @Input() disableButton: Boolean = false;
  @Input() isSkipping: boolean;
  @Input() isSubmitting: boolean;
  @Input() isBannedOrDisabled: boolean;
  @Input() tutorialMapKind: TutorialMapKind;
  @Input() states = [];

  @Output() responseKeyup = new EventEmitter();
  @Output() submitClick = new EventEmitter();
  @Output() formMouseMove = new EventEmitter();
  @Output() formSkip = new EventEmitter();
  @Output() formSubmit = new EventEmitter();
  readonly TutorialMapKind = TutorialMapKind;
  years = [];
  days = [];
  months = MONTHS;
  selectedYear = null;
  selectedMonth = null;
  selectedDay = null;

  response = '';
  zipCodeMaxLength = 5;

  constructor(private sdk: SuzySdkService) {}

  ngOnInit(): void {
    this.response = this.isSpecialMission ? 'special mission' : '';
    this.populateYears();
    this.updateDaysInMonth();
  }

  populateYears(): void {
    const currentYear = moment().year();
    const earliestYear = currentYear - 120; // Allow up to 120 years in the past
    this.years = [];
    for (let year = currentYear; year >= earliestYear; year--) {
      this.years.push(year);
    }
  }

  updateDaysInMonth(): void {
    const daysInMonth = new Date(
      this.selectedYear,
      this.selectedMonth,
      0
    ).getDate();
    this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    if (this.selectedDay > daysInMonth) {
      this.selectedDay = daysInMonth;
    }
  }

  onBirthDateChange(): void {
    this.updateDaysInMonth();
  }

  zipCodeValidator(event: any) {
    if (!/^(?:\d|-|Backspace|Delete|ArrowLeft|ArrowRight)$/.test(event.key)) {
      event.preventDefault();
    } else {
      this.responseKeyup.emit();
    }
  }

  onResponseKeyup(): void {
    this.responseKeyup.emit();
  }

  onMouseMove(event): void {
    this.formMouseMove.emit(event);
  }

  onSubmitClick(): void {
    if (this.tutorialMapKind === TutorialMapKind.birthdate) {
      this.response = `${this.selectedDay}/${this.selectedMonth}/${this.selectedYear}`;
    }

    this.submitClick.emit(this.response);
  }

  onFormSkip(event): void {
    this.formSkip.emit(event);
  }

  onFormSubmit(event: any): void {
    this.formSubmit.emit(event);
  }
}
