import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable } from 'rxjs';
import { SettingsService } from '../../../core/services/settings.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ISegmentMissionFeedback,
  SegmentService
} from '@suzy/shared/data-access/tracking';
import { MissionService } from '@suzy/shared/tools/mission';

@Component({
  selector: 'app-flag-mission-modal',
  templateUrl: './flag-mission-modal.component.html'
})
export class FlagMissionMoadlComponent implements OnInit {
  darkMode$!: Observable<boolean>;
  noSelection?: boolean;
  flagOptions: Array<{ label: string; value: number; checked: boolean }> = [];

  @Input() mission: any;
  @Input() action?: any;

  constructor(
    public modal: NgbActiveModal,
    private settingsService: SettingsService,
    private segmentService: SegmentService,
    private missionService: MissionService,
    private translate: TranslateService
  ) {
    this.darkMode$ = this.settingsService.isDarkModeSelected$;
  }

  ngOnInit(): void {
    this.prepareOptions();
  }

  closeModal(): void {
    this.modal.dismiss();
  }

  onSubmitClick(): void {
    const hasSelectedItems = this.flagOptions.some(x => x.checked);
    if (!hasSelectedItems) {
      this.noSelection = true;
      return;
    }

    const trackProperties: ISegmentMissionFeedback = {
      brand_id: this.mission.brand_id,
      mission_id: this.mission.mission_id,
      mission_kind: this.mission.mission_kind,
      feedback: this.flagOptions
        .filter(x => x.checked)
        .map(x => x.label)
        .join(', ')
    };
    if (this.action) {
      const item = this.missionService.getActionByKind(this.action);
      (trackProperties.action_id = this.action.action_id),
        (trackProperties.action_kind = this.action.action_kind);
      trackProperties.action_name = item.question;
      trackProperties.grid_kind = item.grid_kind;
      trackProperties.multiple_choice_kind = item.multiple_choice_kind;
    }

    this.segmentService.trackMissionFeedback(trackProperties);
    setTimeout(() => {
      this.modal.dismiss();
    }, 100);
  }

  onCheckBoxChange(value: number): void {
    const item = this.flagOptions.find(x => x.value === value);
    if (!item) {
      return;
    }
    item.checked = !item.checked;
    if (item.checked) {
      this.noSelection = false;
    }
  }

  private prepareOptions(): void {
    const flagOptions = [1, 2, 3, 4];
    const translations$: Observable<string>[] = [];
    flagOptions.forEach(ix => {
      const translate$ = this.translate.get(`missionFeedback.${ix}`);
      translations$.push(translate$);
    });

    combineLatest(translations$).subscribe(translations => {
      flagOptions.forEach((value, ix) => {
        this.flagOptions.push({
          value,
          checked: false,
          label: translations[ix]
        });
      });
    });
  }
}
