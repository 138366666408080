import {
  AfterContentChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import { GlobalIsolateSdkService } from '@suzy/shared/data-access/global-isolate-sdk';
import {
  MissionKind,
  PreRollKind,
  SuzySdkService
} from '@suzy/shared/data-access/suzy-sdk';
import { SegmentService } from '@suzy/shared/data-access/tracking';
import { MissionService } from '@suzy/shared/tools/mission';
import { LightboxComponent } from '@suzy/shared/ui/lightbox';
import { environment } from 'apps/crowdtap/src/environments/environment';
import { Subject } from 'rxjs';
import { MetaData } from '../../../core/models/meta-data.model';
import { AppAlertService } from '../../../core/services/app-alert/app-alert.service';
import { MetaDataService } from '../../../core/services/meta-data.service';
import { MissionExpiredService } from '../../../core/services/mission-expired/mission-expired.service';
import { SettingsService } from '../../../core/services/settings.service';
import { GlobalService } from '../../global/global.service';
import { SkipModalComponent } from '../skip-modal/skip-modal.component';
import { SkipModalService } from '../../../core/services/skip-modal-service';
import { beforeModalDismiss } from '../actions.service';
import { take } from 'rxjs/operators';

interface FakeCorner {
  top: string;
  left: string;
  width: string;
  height: string;
  isSet: boolean;
}

@Component({
  selector: 'app-maxDiff',
  templateUrl: './maxDiff.component.html',
  styleUrls: ['./maxDiff.component.scss']
})
export class MaxDiffComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  activeColor: any = '';
  action: any = {};
  mission: any = {};
  errorMessage = '';
  isLoading = false;
  isSuccess = false;
  isSubmitting = false;
  isPreview: boolean;

  hasPreroll = false;
  prerollViewed = false;
  preroll_image: number = PreRollKind.image;
  preroll_link: number = PreRollKind.link;

  preRollKind = PreRollKind.none;
  preRollCaption = '';
  preRollUrl = '';

  metaData: MetaData;
  startDate: number;
  timeToAction: number;
  clientX: number;
  clientY: number;
  disableSkip: boolean;
  startTime: number;
  isSkipping: boolean;

  imageModalIsActive: boolean;
  activeImageUrl: string;
  activeImageAltText: string;
  activeAnswerChoiceText: string;
  currentChoiceIndex: number;
  mcOptionsExcludingNone = [];
  subject: Subject<any> = new Subject<any>();
  showSurveyProgress = false;
  isBannedOrDisabled = false;
  canFlagAction?: boolean;

  @ViewChild('originalCorner') originalCorner: ElementRef;
  fakeCorner: FakeCorner;

  private trackActionName = true;

  constructor(
    private suzySDK: SuzySdkService,
    private globalSDK: GlobalIsolateSdkService,
    private alerts: AppAlertService,
    private missionService: MissionService,
    private skipModalService: SkipModalService,
    private modals: NgbModal,
    private renderer: Renderer2,
    private metaDataService: MetaDataService,
    private segmentService: SegmentService,
    private missionExpiredService: MissionExpiredService,
    private hostElement: ElementRef,
    private settingsService: SettingsService,
    private globalService: GlobalService,
    private launchDarklyService: LaunchDarklyService
  ) {}
  ngOnInit(): void {
    this.fakeCorner = {
      top: '0',
      left: '0',
      width: '0',
      height: '0',
      isSet: false
    };
    this.startDate = Date.now();
    this.startTime = new Date().getTime();
    this.trackActionName = this.launchDarklyService.getGLB723Flag();
    this.showSurveyProgress =
      this.launchDarklyService.getCWDT21752418SurveyProgress() &&
      this.missionService.isSurveyInProgress(this.mission);

    this.metaData = {
      userId: '',
      type: 'maxDiff',
      actionId: undefined,
      missionId: undefined,
      text: this.action.maxdiff.question,
      response: undefined,
      attempt: this.startDate,
      startDateTime: this.startDate,
      actionStartDateTime: this.startDate,
      completedDateTime: undefined,
      dwellingTime: undefined,
      completionTime: undefined,
      interactionDateTime: undefined,
      closedDateTime: undefined,
      skipDateTime: undefined,
      skipFlag: false,
      mousePosition: undefined,
      deviceInfo: undefined,
      validationCount: 0,
      questionCounts: undefined,
      responseCount: undefined,
      numberOfOptions: undefined,
      optionPositions: undefined
    };

    this.preRollKind = this.action.preroll_kind;
    this.preRollCaption = this.action.preroll_instruction;

    this.segmentService.trackActionStarted({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      action_name: this.trackActionName
        ? this.action.maxdiff.question
        : undefined,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      preroll_kind: this.action.preroll_kind,
      image_answers: false,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      monadic_variant_id:
        this.action.has_monadic && this.action.monadic_variants.length === 1
          ? this.action.monadic_variants[0].monadic_variant_id
          : undefined,
      points_available:
        this.mission.action_count === 1
          ? this.mission.points
          : this.action.points_extra,
      tag: this.mission.specialType
    });

    if (
      this.action.preroll_kind !== 'undefined' &&
      this.action.preroll_kind !== PreRollKind.none
    ) {
      if (
        this.action.preroll_kind === PreRollKind.image &&
        this.action.preroll
      ) {
        this.hasPreroll = true;
        this.preRollUrl = this.action.preroll.thumbs.full.url;
      } else if (
        this.action.preroll_kind === PreRollKind.link &&
        this.action.preroll_body
      ) {
        this.hasPreroll = true;
        this.preRollUrl = this.action.preroll_body;
      }
    }
    this.renderer.addClass(document.body, 'isMaxdiffAction');

    if (
      (this.mission.first_action && this.mission.first_action.prevent_skip) ||
      this.mission.mission_kind !== MissionKind.standard
    ) {
      this.disableSkip = this.mission.first_action.prevent_skip;
    }

    this.activeColor = this.mission.color;

    this.action.maxdiff.attributes.forEach(option => {
      this.mcOptionsExcludingNone.push(option);
    });
    this.isPreview = this.settingsService.isPreviewApp();
    this.skipModalService.isSkip.pipe(take(1)).subscribe(data => {
      if (data === this.metaData.type) {
        this.skipAction();
      }
    });

    this.canFlagAction =
      this.launchDarklyService.getCWDT2642And2646AbilityToFlagMissions();
  }

  init(): void {
    // Do NOT remove
    // ActionLoaderComponent requires this
  }

  ngOnDestroy(): void {
    this.subject.complete();
    this.subject = undefined;
    this.renderer.removeClass(document.body, 'isMaxdiffAction');
  }

  ngAfterContentChecked(): void {
    this.positionFakeCorner();
  }

  positionFakeCorner(reset = false): void {
    if (reset) {
      this.fakeCorner.isSet = false;
    }
    if (!this.fakeCorner.isSet && this.originalCorner) {
      const corner = this.originalCorner.nativeElement.getBoundingClientRect();
      this.fakeCorner.width = `${Math.ceil(corner.width)}px`;
      this.fakeCorner.height = `${Math.ceil(corner.height)}px`;
      this.fakeCorner.isSet = true;
    }
  }

  onPrerollViewedEvent(guid?: string): void {
    this.segmentService.trackMediaOpen({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      asset_id: this.action.preroll ? this.action.preroll.asset_id : undefined,
      asset_url: this.preRollUrl,
      preroll_kind: this.preRollKind,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      is_on_question: false,
      image_attribuion: guid
    });
    this.prerollViewed = true;
  }

  onViewPrerollImage(guid?: string): void {
    this.segmentService.trackMediaOpen({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      asset_id: this.action.preroll ? this.action.preroll.asset_id : undefined,
      asset_url: this.preRollUrl,
      preroll_kind: this.preRollKind,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      is_on_question: true,
      image_attribuion: guid
    });
  }

  onViewPrerollLink(): void {
    this.segmentService.trackMediaOpen({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      asset_id: this.action.preroll ? this.action.preroll.asset_id : undefined,
      asset_url: this.preRollUrl,
      preroll_kind: this.preRollKind,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      is_on_question: true
    });
  }
  onUpdateMetaDataValidationCount(): void {
    this.metaData.validationCount++;
  }

  skipAction() {
    if (this.isSubmitting) {
      return;
    }
    this.sendMetaData('skip');
    this.isSubmitting = true;
    this.isSkipping = true;

    this.hostElement.nativeElement
      .closest('.modal')
      .classList.add('is-skipping');
    const input = {
      brand_id: this.mission.brand_id,
      mission_id: this.mission.mission_id,
      action_id: this.action.action_id
    };
    this.suzySDK.ProtocolRespond.skip(
      this.mission.brand_id,
      this.mission.mission_id,
      input
    ).subscribe(
      data => {
        if (data.success) {
          this.skipModalService.isSuccess.next(true);
          setTimeout(() => {
            this.subject.next('skip');
            this.isSkipping = false;

            if (this.mission.action_count === 1) {
              this.segmentService.trackMission('Mission Skipped', {
                action_count: this.mission.action_count,
                brand_id: this.mission.brand_id,
                mission_id: this.mission.mission_id,
                points_available: this.mission.points,
                mission_kind: this.mission.mission_kind,
                has_cover: false,
                monadic_variant_quantity: this.mission.monadic_variants
                  ? this.mission.monadic_variants.length
                  : undefined,
                tag: this.mission.specialType,
                weight: this.mission.weight
              });
            }
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
          }, 1000);
        } else {
          this.skipModalService.isSuccess.next(false);
          let message = data.message;
          this.isSkipping = false;
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }
          this.alerts.notify({ title: 'Error', message });
        }
      },
      error => {
        this.skipModalService.isSuccess.next(false);
        this.isSkipping = false;
        this.alerts.notify({ title: 'Error', message: error });
      },
      () => {
        this.isSubmitting = false;
      }
    );
  }

  openSkipModal(): void {
    const modalClass = `modal-${new Date().getTime()}`;
    let modal;
    modal = this.modals.open(SkipModalComponent, {
      windowClass: modalClass,
      beforeDismiss: () => {
        return beforeModalDismiss(modalClass, 'skip');
      }
    });
    const instance = modal.componentInstance as SkipModalComponent;
    instance.kind = this.mission.mission_kind;
    instance.mission = this.mission;
    instance.action = this.mission.first_action;
    instance.actionService = null;
    instance.actionType = this.metaData.type;
  }

  onSkip($event): void {
    $event.preventDefault();
    this.openSkipModal();
  }

  onMouseMoveEvent(event: MouseEvent): void {
    this.clientX = event.clientX;
    this.clientY = event.clientY;
  }

  onTimeToActionUpdate(eventData: number): void {
    this.timeToAction = eventData;
  }

  sendMetaData(actionType: string): void {
    let completedDateTime: number;
    let skipDateTime: number;
    let skipFlag: boolean;
    switch (actionType) {
      case 'submit':
        completedDateTime = Date.now();
        break;
      case 'skip':
        skipDateTime = Date.now();
        skipFlag = true;
        break;
      default:
        break;
    }

    const meta = {
      ...this.metaData,
      actionId: this.action.action_id,
      missionId: this.mission.mission_id,
      text: this.action.maxdiff.question,
      completedDateTime,
      interactionDateTime: this.timeToAction,
      skipDateTime,
      skipFlag,
      mousePosition: { x: this.clientX, y: this.clientY }
    };

    this.metaDataService.createMetaData(this.metaData);
  }

  onSubmit(): void {
    if (this.isSubmitting) {
      return;
    }

    this.sendMetaData('submit');
    this.segmentService.trackAction('Action Completed', {
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      action_name: this.trackActionName
        ? this.action.maxdiff.question
        : undefined,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      monadic_variant_id:
        this.action.has_monadic && this.action.monadic_variants.length === 1
          ? this.action.monadic_variants[0].monadic_variant_id
          : undefined,
      tag: this.mission.specialType
    });
    this.isSubmitting = true;
    this.isLoading = true;
    if (
      this.mission.isGlobal ||
      this.settingsService.isPreviewApp() ||
      this.settingsService.isGlobalApp()
    ) {
      this.globalResponse(this.action.pairAnswers);
    } else {
      this.crowdtapResponse(this.action.pairAnswers);
    }
  }

  crowdtapResponse(pairAnswers): void {
    this.suzySDK.ProtocolRespond.respond(
      this.mission.brand_id,
      this.mission.mission_id,
      {
        action_id: this.action.action_id,
        answer_pairs: pairAnswers,
        correlation_data: this.action.correlation_data
          ? this.action.correlation_data
          : undefined
      }
    ).subscribe(
      data => {
        if (data.success) {
          if (data.item) {
            setTimeout(() => {
              this.action = data.item;
            }, 1600);
          }

          this.isLoading = false;
          this.isSuccess = true;
          setTimeout(() => {
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
            this.subject.next(data);
          }, 800);
        } else {
          this.isBannedOrDisabled = ['user.Banned', 'user.Disabled'].some(
            x => x === data.message_token
          );

          let message = data.message;
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }

          this.segmentService.trackActionError({
            action_id: this.action.action_id,
            action_kind: this.action.action_kind,
            brand_id: this.mission.brand_id,
            error_code: data.error_code,
            error_source: 'backend',
            error_message: message,
            error_ui_message: message,
            mission_id: this.mission.mission_id,
            mission_kind: this.mission.mission_kind,
            monadic_variant_id:
              this.action.has_monadic &&
              this.action.monadic_variants.length === 1
                ? this.action.monadic_variants[0].monadic_variant_id
                : undefined,
            tag: this.mission.specialType
          });

          this.isLoading = false;
          let errorCode = [404, 409, 401];
          if (errorCode.includes(data.error_code)) {
            this.openMissionExpiredModal(message);
          } else {
            this.errorMessage = message;
          }
        }
      },
      error => {
        this.isLoading = false;
        this.isSubmitting = false;
        let errorCode = [404, 409, 401];
        if (errorCode.includes(error.error_code)) {
          this.openMissionExpiredModal(error.message);
          this.segmentService.trackActionError({
            action_id: this.action.action_id,
            action_kind: this.action.action_kind,
            brand_id: this.mission.brand_id,
            error_code: error.error_code,
            error_source: 'backend',
            error_message: error.message,
            error_ui_message: error.message,
            mission_id: this.mission.mission_id,
            mission_kind: this.mission.mission_kind,
            monadic_variant_id:
              this.action.has_monadic &&
              this.action.monadic_variants.length === 1
                ? this.action.monadic_variants[0].monadic_variant_id
                : undefined,
            tag: this.mission.specialType
          });
        } else {
          this.isBannedOrDisabled = false;
          this.errorMessage = error;
          this.segmentService.trackActionError({
            action_id: this.action.action_id,
            action_kind: this.action.action_kind,
            brand_id: this.mission.brand_id,
            error_source: 'backend',
            error_message: this.errorMessage,
            error_ui_message: this.errorMessage,
            mission_id: this.mission.mission_id,
            mission_kind: this.mission.mission_kind,
            monadic_variant_id:
              this.action.has_monadic &&
              this.action.monadic_variants.length === 1
                ? this.action.monadic_variants[0].monadic_variant_id
                : undefined,
            tag: this.mission.specialType
          });
        }
      }
    );
  }

  globalResponse(input): void {
    input = {
      payload: {
        action_id: this.action.action_id,
        answer_pairs: input,
        correlation_data: this.action.correlation_data
          ? this.action.correlation_data
          : undefined
      }
    };
    this.globalService.respondToGlobalActionAsync(input).subscribe(
      data => {
        if (data.success) {
          this.action = data.item;
          this.isLoading = false;
          this.isSuccess = true;
          setTimeout(() => {
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
            this.subject.next(data);
          }, 800);
        } else {
          this.isBannedOrDisabled = ['user.Banned', 'user.Disabled'].some(
            x => x === data.message_token
          );
          let message = data.message;
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
            this.errorMessage = message;
          }

          this.segmentService.trackActionError({
            action_id: this.action.action_id,
            action_kind: this.action.action_kind,
            brand_id: this.mission.brand_id,
            error_code: data.error_code,
            error_source: 'backend',
            error_message: message,
            error_ui_message: message,
            mission_id: this.mission.mission_id,
            mission_kind: this.mission.mission_kind,
            monadic_variant_id:
              this.action.has_monadic &&
              this.action.monadic_variants.length === 1
                ? this.action.monadic_variants[0].monadic_variant_id
                : undefined,
            tag: this.mission.specialType
          });

          this.isLoading = false;
          this.isSubmitting = false;
        }
      },
      error => {
        this.isLoading = false;
        this.isSubmitting = false;
      }
    );
  }
  openMissionExpiredModal(message: any) {
    this.missionExpiredService.notify({
      mission: this.mission,
      title: 'Error',
      message: message
    });
  }

  viewOptionImage(event: { option: any; index?: number }): void {
    this.imageModalIsActive = true;

    if (this.isPreview) {
      window.scrollTo(0, 0);
    }

    if (event.option.asset) {
      this.activeImageUrl = event.option.asset.thumbs.full.url;
    } else if (event.option.asset_upload) {
      this.activeImageUrl = event.option.asset_upload.url;
    } else {
      this.activeImageUrl = '';
    }
    this.activeImageAltText = event.option.asset_alt_text;
    this.activeAnswerChoiceText = event.option.answer_text;
    this.currentChoiceIndex = event.index
      ? event.index
      : this.mcOptionsExcludingNone.findIndex(
          opt => event.option.answer_text === opt.answer_text
        );
  }

  previousImage(i: number): void {
    let prevOption: any;
    if (i === 0) {
      prevOption =
        this.mcOptionsExcludingNone[this.mcOptionsExcludingNone.length - 1];
    } else {
      prevOption = this.mcOptionsExcludingNone[i - 1];
    }
    this.viewOptionImage({ option: prevOption });
  }

  nextImage(i: number): void {
    let nextOption: any;
    if (i >= this.mcOptionsExcludingNone.length - 1) {
      nextOption = this.mcOptionsExcludingNone[0];
    } else {
      nextOption = this.mcOptionsExcludingNone[i + 1];
    }
    this.viewOptionImage({ option: nextOption });
  }

  get calculateCompletion(): string {
    return (
      Math.round(
        (this.action.step_priority / this.mission.action_count) * 100
      ) + '%'
    );
  }
}
