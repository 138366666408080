import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Mission } from '../../../views/actions/mission.model';
import {
  MissionKind,
  ActionKind,
  ActionStructureMultipleChoiceKind,
  TutorialMapKind
} from '@asksuzy/typescript-sdk';
import { MetaData } from '../../../core/models/meta-data.model';
import { MetaDataService } from '../../../core/services/meta-data.service';
import {
  segment_special_tile,
  SegmentService
} from '@suzy/shared/data-access/tracking';
import { ActionsService } from '../actions.service';
import { TranslateService } from '@ngx-translate/core';
import { MissionService } from '@suzy/shared/tools/mission';
import { SettingsService } from '../../../core/services/settings.service';
import { environment } from 'apps/crowdtap/src/environments/environment';
import { FlagMissionMoadlComponent } from '../flag-mission-modal/flag-mission-modal.component';

@Component({
  selector: 'app-action-modal-header',
  templateUrl: './action-modal-header.component.html',
  styleUrls: ['./action-modal-header.component.scss']
})
export class ActionModalHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() mission: Mission;
  @Input() action: any;
  @Input() metaData: MetaData;
  @Input() isSpecialMission: boolean;
  @Input() disableButton: Boolean = false;
  @Input() typeIndicatorColor: string;
  @Input() canFlagAction?: boolean;
  showCloseIcon: boolean = false;
  showFlagIcon: boolean = false;
  activeColor: any;
  isPreview: boolean;
  isExternal: boolean;
  missionKind = MissionKind;
  isLegalAction = false;
  isDarkMode: boolean;
  readonly SpecialTileType = segment_special_tile;
  readonly TutorialMapKind = TutorialMapKind;

  constructor(
    public modal: NgbActiveModal,
    public settingsService: SettingsService,
    private metaDataService: MetaDataService,
    private actionService: ActionsService,
    private translate: TranslateService,
    private segmentService: SegmentService,
    private missionService: MissionService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.showCloseIcon = !(
      environment.global ||
      environment.preview ||
      environment.externalLink
    );
    this.showFlagIcon =
      !(
        environment.global ||
        environment.preview ||
        environment.externalLink
      ) && this.canFlagAction;
    if (this.mission) {
      this.activeColor = this.mission.color;
      this.title =
        this.mission.mission_kind === MissionKind.standard
          ? this.getTitleByActionType(this.mission)
          : this.getTitleByMissionType(this.mission);
      if (this.mission.mission_kind === MissionKind.external_link) {
        this.isLegalAction = this.action?.is_legal_consent;
      }
    }

    this.isPreview = this.settingsService.isPreviewApp();
    this.isExternal = this.settingsService.isExternalApp();
    this.settingsService.isDarkModeSelected$.subscribe(value => {
      this.isDarkMode = value;
    });
  }

  getTitleByActionType(mission: Mission): string {
    switch (mission.first_action.action_kind) {
      case ActionKind.multiplechoice:
        if (
          mission.first_action.multiple_choice?.multiple_choice_kind ===
          ActionStructureMultipleChoiceKind.monadic
        ) {
          return 'Rating';
        } else {
          return this.translate.instant('multipleChoice.multipleChoice');
        }
      case ActionKind.openended:
        return this.translate.instant('openEnded.openEnded');
      case ActionKind.photoacquisition:
        return 'Photo';
      case ActionKind.grid:
        return 'Grid';
      case ActionKind.gridcustom:
        return this.translate.instant('grid.customGrid');
      case ActionKind.gridrankscale:
        return 'Scale/Rank';
      case ActionKind.turf:
        return this.translate.instant('multipleChoice.multipleChoice');
      default:
        console.error(
          `Action doesn't not support action kind ${mission.first_action.action_kind}`
        );
        break;
    }
  }

  getTitleByMissionType(mission: Mission): string {
    switch (mission.mission_kind) {
      case MissionKind.splittesting:
      case MissionKind.survey:
        return this.translate.instant('survey.survey');
      case MissionKind.screening:
        return this.translate.instant('survey.survey');
      case MissionKind.maxdiff:
        return this.translate.instant('survey.survey');
      case MissionKind.external_cint:
      case MissionKind.external_link:
        return;
      case MissionKind.wave:
        return this.translate.instant('survey.survey');
      default:
        console.error(
          `Action doesn't support mission kind ${mission.mission_kind}`
        );
        break;
    }
  }

  closeModal(): void {
    this.missionService.setMissionOpen(false);
    if (this.metaData) {
      this.sendMetaData(this.metaData);
    }
    this.segmentService.trackMission('Mission Closed', {
      action_count: this.mission.action_count,
      brand_id: this.mission.brand_id,
      mission_id: this.mission.mission_id,
      points_available: this.mission.points,
      mission_kind: this.mission.mission_kind,
      has_cover: this.mission.mission_caption ? true : false,
      monadic_variant_quantity: this.mission.monadic_variants
        ? this.mission.monadic_variants.length
        : undefined,
      tag: this.mission.specialType,
      weight: this.mission.weight
    });
    if (this.actionService.currentActionStepPriority$.getValue() > 1) {
      this.modal.dismiss('in-progress');

      this.actionService.currentActionStepPriority$.next(-1);

      return;
    }
    this.modal.dismiss();
  }

  sendMetaData(data: MetaData): void {
    data.closedDateTime = Date.now();
    this.metaDataService.createMetaData(data);
  }

  onFlagClick(): void {
    const flagModal = this.modalService.open(FlagMissionMoadlComponent, {
      centered: true,
      windowClass: 'general-modal',
      modalDialogClass: 'flag-mission-modal'
    });
    flagModal.componentInstance.mission = this.mission;
    flagModal.componentInstance.action = this.action;
  }
}
